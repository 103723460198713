import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Explore Our Services at Wild Tracks ATV Rentals
			</title>
			<meta name={"description"} content={"Your Gateway to Unforgettable Journeys"} />
			<meta property={"og:title"} content={"Our Services | Explore Our Services at Wild Tracks ATV Rentals"} />
			<meta property={"og:description"} content={"Your Gateway to Unforgettable Journeys"} />
			<meta property={"og:image"} content={"https://cuvixotrade.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cuvixotrade.com/img/2078292.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cuvixotrade.com/img/2078292.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cuvixotrade.com/img/2078292.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cuvixotrade.com/img/2078292.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cuvixotrade.com/img/2078292.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cuvixotrade.com/img/2078292.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://cuvixotrade.com/img/4.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Customized ATV Adventure Services
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						At Wild Tracks ATV Rentals, we're dedicated to offering an all-encompassing adventure experience that goes beyond just renting ATVs. Our suite of services is designed to cater to every aspect of your outdoor adventure, ensuring you have a memorable and hassle-free experience. Here's an overview of what we offer to ignite your spirit of adventure:
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Override slot="SectionContent" max-width="1220px" />
			<Box margin="0px 0px 10px 0px" padding="15px 15px 15px 15px" md-margin="0px 0px 40px 0px" lg-margin="0px 0px 56px 0px">
				<Text
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Our Services
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Guided Tours
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Personalized Routes: Tailor your journey with tours designed for your experience level and interest.
							<br />
							<br />
							Knowledgeable Guides: Led by experts passionate about the outdoors and safety.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					ATV Rentals
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Varied Selection: A range of ATVs to match your adventure spirit, from beginner-friendly to advanced models.
							<br />
							<br />
							Ready to Ride: Every vehicle undergoes a thorough maintenance check before each rental.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Safety Equipment
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							All-Inclusive Gear: High-quality helmets, gloves, and goggles provided for your protection.
							<br />
							<br />
							Pre-Ride Briefings: Comprehensive safety tutorials to prepare you for the trail ahead.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
					Trail Advice
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Custom Itineraries: Assistance in planning your ride with our insider knowledge of the best trails.
							<br />
							<br />
							Weather Preparedness: Updates and tips to keep you safe and comfortable in any weather.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="--color-light"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box border-radius="24px" margin="0px 0px 20px 0px" md-margin="0px 0px 10px 0px" width="80%">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Ready to Adventure With Us?
				</Text>
			</Box>
			<Box lg-flex-wrap="wrap">
				<Text
					margin="20px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					md-text-align="center"
				>
					To dive deeper into what Wild Tracks ATV Rentals can offer for your next outdoor adventure, get in touch with us today. Our team is eager to answer your queries, help you pick the perfect service package, and ensure you’re fully equipped for an unforgettable journey. Whether you’re a seasoned explorer or new to the trails, we’re here to make your adventure dreams a reality.
				</Text>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Discover the Wild, The Wild Tracks Way – Adventure Awaits!
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});